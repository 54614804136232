import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center" }}>
            Hi Everyone, I am <span className="purple">Halim Aslıyüksek </span>
            from <span className="purple">Turkey.</span>
            <br /> I am a final year Software Engineering student
            in Samsun University
            <br />
            Also, I am currently working as a freelance software developer.
            <br />
            <br />
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "If they say it is impossible, it is for them, not for you."{" "}
          </p>
          <footer className="blockquote-footer">Halimaslyk</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
