import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import mychat from "../../Assets/Projects/mychat.png";
import samusocial from "../../Assets/Projects/samusocial.png";
import todo from "../../Assets/Projects/todo.png";
import spotify from "../../Assets/Projects/spotify.png";
import healthy from "../../Assets/Projects/healthy.png";
import signup from "../../Assets/Projects/signup.png";
import iasgo from "../../Assets/Projects/iasgo.png";
import criptic from "../../Assets/Projects/criptic.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={spotify}
              isBlog={false}
              title="Spotify UI"
              description="Experience Spotify like never before with my minimalist and visually captivating interface redesign, designed to elevate your music exploration journey"
              ghLink="https://github.com/Haslyk/SpotifyClone"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={signup}
              isBlog={false}
              title="Awesome Login Page"
              description=" Immerse yourself in the seamless blend of form and function with my thoughtfully crafted login and signup pages. Elevate user engagement through an intuitive and aesthetically pleasing interface."
              ghLink="https://github.com/Haslyk/Login-form"
              demoLink="https://loginform-halimaslyk.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title="To Do App"
              description="Revolutionize task management and team coordination with our Project Task Tracking solution! Empowering team leaders to seamlessly assign and monitor tasks, this innovative platform ensures efficient communication. Elevate your project management with our smart solution today!"
              ghLink="https://github.com/Haslyk/ToDoApp"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mychat}
              isBlog={false}
              title="My Chat App"
              description="Introducing MyChat – Your Instant Connection to Real-Time Conversations! Powered by Node.js and Socket.io, MyChat lets you chat seamlessly with others in live, lightning-fast discussions. Join now to experience communication in its most dynamic form!"
              ghLink="https://github.com/Haslyk/myChatApp"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={healthy}
              isBlog={false}
              title="Healthy Life"
              description="Welcome to Healthy Life – Your Pathway to Wellness! Discover a comprehensive health hub featuring personalized diet and workout plans, precise calorie calculations, and a treasure trove of wholesome recipes. Embark on your journey to a healthier you with Healthy Life today!"
              ghLink="https://github.com/Haslyk/HealthyLife"
             />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={samusocial}
              isBlog={false}
              title="SAMU Social"
              description="Your Personalized Campus Connection. Fueling creativity and camaraderie, SAMU Social is your gateway to propose, vote on, and bring to life student-driven events. Witness your ideas transform into reality as the highest voted concepts light up your campus. Join the movement and shape unforgettable university experiences with SAMU Social!"
              ghLink="https://github.com/Haslyk/SamuEtkinlik"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={iasgo}
              isBlog={false}
              title="IASGO TURKEY"
              description="This table is the 33rd edition of IASGO Turkey, which focuses on the treatment of gastrointestinal diseases. This is a design I made for the World Congress. My design reflects the colorful and dynamic vision of IASGO. Various shapes related to the gastrointestinal tract and the logo of IASGO are used in the table. My design shows the richness that IASGO Turkey offers to the congress participants."
              demoLink="https://iasgoturkey.com/" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={criptic}
              isBlog={false}
              title="Criptic"
              description="Your Gateway to the Crypto Universe. Immerse yourself in a sleek and intuitive platform, where you can explore, track, and engage with the dynamic world of cryptocurrencies. Uncover insights, make informed decisions, and stay ahead in the crypto game with Criptic!"
              ghLink="https://github.com/Haslyk/MVCmodel"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
