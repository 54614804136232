import React, {useRef, useState} from "react"
import emailjs from "@emailjs/browser"
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import contactGif from "../../Assets/contactGif.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')



  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if(name && mail && message) {
      emailjs.sendForm('service_mn754aa', 'template_8k2ft49', form.current, '7ubjo-0GfH2VKi7In')
       .then((result) => {
           console.log(result.text);
           toast.success('Successfully sended!', {
             position: "top-center",
             autoClose: 3000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             });
           setTimeout(() => {
             window.location.reload()
           }, 4000)
       }, (error) => {
           console.log(error.text);
           toast.error('Ups! An error occurred.', {
             position: "top-center",
             autoClose: 3000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             });
           setTimeout(() => {
             window.location.reload()
           }, 4000)
       });
    }
    else {
      toast.error('Please complete the form in its entirety!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

     
  };


  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container  style={{minHeight: "65vh", display : "flex", flexDirection : "column", justifyContent: "center"}}>
        <h1 className="contact-heading">
          Contact <strong className="purple">Us </strong>
        </h1>
        <Row style={{justifyContent : "center", padding: "10px", height : "100%"}}>
            <Col md={6}>
              <img src={contactGif} className='contact-img' style={{width: "70%", borderRadius: "50%"}} />
            </Col>
            <Col md={6}>
              <Card className='contact-us'>
                <Row>
                  <form ref={form} onSubmit={sendEmail}>
                    <Col md={12} xs={12}>
                      <h2>
                        Get in touch
                      </h2>
                    </Col>
                    <Col md={12} xs={12}>
                      <input type='text' name="full_name" onChange={(e) => setName(e.target.value)} required placeholder='Full Name*'/>
                    </Col>
                    <Col md={12} xs={12}>
                      <input type='email' name="user_mail" onChange={(e) => setMail(e.target.value)} required placeholder='Email*'/>
                    </Col>
                    <Col md={12} xs={12}>
                      <textarea name="message" onChange={(e) => setMessage(e.target.value)} required placeholder='Message*'></textarea>
                    </Col>
                    <Col md={5} xs={8}>
                      <button type="submit" className='btn btn-primary'>
                        Send Message
                      </button>
                    </Col>
                  </form>  
                </Row>
              </Card>
            </Col>
        </Row>
        
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      

    </Container>

  )
}

export default Contact
